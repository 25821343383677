.button {
    padding: 5px 15px;
    font-size: 1em;
    font-weight: bold;
    background: #ffbd08;
    color: var(--tg-theme-button-text-color);
    border: none;
    cursor: pointer;
    border-radius: .5em;
    transition: all .2s ease-in-out;
}

.button:hover {
    background: #e7ab07;
    scale: 1.05;
}