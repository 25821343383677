.product {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 170px;
}

.title {
    margin-top: .6rem;
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: auto;
}

.img {
    width: 100%;
    height: 100px;
    margin: 0 auto;
    /* background: var(--tg-theme-button-color); */
}

.description {
    margin: .7rem 0;
    font-size: 0.8em;
}

.add-btn {
    width: 100%;
    display: flex;
}

.price {
    font-weight: lighter;
    font-size: 1.1em;
    margin-bottom: 0.3em;
}